import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/BDAHome.vue";
// import About from "../views/AboutBDA.vue";
// import BDAZones from "../views/BDAZones.vue";
// import AccessControl from "../views/AccessControl.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    // lazy-loaded
    component: () => import('../views/BDAHome.vue')

  },
  {
    path: "/home",
    name: "Home",
    // component: Home,
    // lazy-loaded
    component: () => import('../views/BDAHome.vue')

  },
  // {
  //   path: "/login",
  //   name: "Login",
  //   // component: About,
  //   // lazy-loaded
  //   component: () => import('../views/Login.vue')

  // },
  {
    path: "/about",
    name: "About",
    // component: About,
    // lazy-loaded
    component: () => import('../views/AboutBDA.vue')

  },
  {
    path: "/accesscontrol",
    name: "AccessControl",
    // component: AccessControl,
    // lazy-loaded
    component: () => import('../views/AccessControl.vue')
  },
  {
    path: "/uploaddata",
    name: "UploadData",
    // component: AccessControl,
    // lazy-loaded
    component: () => import('../views/UploadData.vue')
  },
  {
    path: "/bdazones",
    name: "BDAZones",
    // component: BDAZones,
    // lazy-loaded
    component: () => import('../views/BDAZones.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    // lazy-loaded
    component: () => import('../views/Profile.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = [];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/home');
  } else {
    next();
  }
});

export default router;
