import axios from 'axios';

const API_URL = 'https://apiserver.shivomrealtors.com/api/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        userName: user.userName,
        userKey: user.userKey
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      userName: user.userName,
      userLevel: user.userLevel,
      userKey: user.userKey,
      roles: user.roles
    });
  }
}

export default new AuthService();
