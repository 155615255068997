<template>
  <v-app>
    <v-snackbar :color="snackBarColor" v-model="snackbar" :timeout="timeout">
      {{ snackBarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue darken-4"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Login Page.vue -->
    <v-card
      color="blue darken-4"
      v-show="!loginStatus"
      width="10000"
      class="mx-auto"
    >
      <v-card-title class="justify-center">
        <v-img
          max-width="200"
          max-height="200"
          class="mt-8"
          src="https://bdasites.in/wp-content/uploads/2021/12/oksadun9rwt5m3jwqcf5.png"
        >
        </v-img>
      </v-card-title>
      <v-card-text>
        <v-card dark v-show="!loginStatus" width="400" class="mx-auto my-12">
          <v-card-title class="justify-center">
            <p class="justify-center">
              Welcome to
              <a
                class="blue--text darken-4"
                href="https://www.bdasites.in"
                target="_blank"
                >BDAsites.in</a
              >
              Admin Panel
            </p>
          </v-card-title>
          <v-card-text width="300">
            <v-text-field
              v-model="userName"
              autofocus
              label="Username"
              prepend-icon="mdi-account"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              @keyup.enter="login()"
              prepend-icon="mdi-lock"
              @click:append="show1 = !show1"
            ></v-text-field>
            <p class="text-right blue--text darken-4">
              <a> Forgot Password? </a>
            </p>
            <p class="text-center">
              <v-btn @click="login()" class="blue darken-4"
                >Login &nbsp;
                <template>
                  <div v-show="loggedIn" class="text-center">
                    <v-progress-circular
                      :size="20"
                      color="white"
                      indeterminate
                    ></v-progress-circular></div
                ></template>
              </v-btn>
            </p>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- App Page -->
    <v-card v-show="loginStatus">
      <v-navigation-drawer
        width="180"
        app
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="https://lh5.googleusercontent.com/-5rSfqNe9t0A/AAAAAAAAAAI/AAAAAAAAAAA/eOaBnQNqJZ4/s160-c-k-mo/photo.jpg"
            >
            </v-img>
          </v-list-item-avatar>

          <v-list-item-title> </v-list-item-title>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.route"
            class="indigo--text"
            link
          >
            <v-list-item-icon>
              <v-icon
                @click="logout()"
                v-if="item.title === 'Logout'"
                class="error--text"
                >{{ item.icon }}</v-icon
              >
              <v-icon v-if="item.title !== 'Logout'">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                @click="logout()"
                v-if="item.title === 'Logout'"
                class="error--text"
                >{{ item.title }}
              </v-list-item-title>
              <v-list-item-title v-if="item.title !== 'Logout'">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <!-- Sizes your content based upon application components -->
    <v-main v-show="loginStatus">
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <!-- <v-footer color="blue darken-4" v-show="loginStatus" app>
      <v-col class="text-left" cols="4">
        <v-btn @click="logout()" class="error--text">
          <v-icon>mdi-logout</v-icon> Logout
        </v-btn>
      </v-col>
      <v-col class="white--text text-center" cols="4">
        <v-icon class="white--text">mdi-copyright</v-icon>
        {{ new Date().getFullYear() }} —
        <strong>BDAsites Admin</strong>
      </v-col>
      <v-col class="text-right white--text" cols="4">
        Powered By —
        <strong>
          <a class="white--text" href="https://www.bdasites.in" target="_blank"
            >BDASites.in</a
          ></strong
        >
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import axios from "axios";
const API_URL = "https://apiserver.shivomrealtors.com/api/excelAPI/";

export default {
  name: "App",
  data() {
    return {
      mini: true,
      drawer: true,
      timeout: 2000,
      loggedIn: false,
      loginStatus: false,
      snackbar: false,
      snackBarColor: "red",
      snackBarText: "",
      userName: "",
      password: "",
      show1: false,
      items: [],
    };
  },

  created() {
    this.initialize();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_MODERATOR");
      }

      return false;
    },
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  },
  methods: {
    async login() {
      let reqObj = {};
      this.loggedIn = true;
      reqObj.userName = this.userName;
      reqObj.userKey = this.password;
      await axios
        .post(API_URL + "loginUser", reqObj)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("auth/login", reqObj).then(
              () => {
                this.snackBarColor = "green";
                this.snackBarText = "Login Success!";
                this.snackbar = true;
                this.loggedIn = false;
                this.loginStatus = !this.loginStatus;
                if (this.currentUser.roles.includes("ROLE_ADMIN")) {
                  this.items = [
                    { title: "Home", route: "/home", icon: "mdi-home" },
                    {
                      title: "BDA Zones",
                      route: "/bdazones",
                      icon: "mdi-home-city",
                    },
                    {
                      title: "Upload Data",
                      route: "/uploaddata",
                      icon: "mdi-upload",
                    },
                    {
                      title: "Access Control",
                      route: "/accesscontrol",
                      icon: "mdi-lock",
                    },
                    {
                      title: "Profile",
                      route: "/profile",
                      icon: "mdi-account",
                    },
                    {
                      title: "About",
                      route: "/about",
                      icon: "mdi-information",
                    },
                    { title: "Logout", icon: "mdi-logout" },
                  ];
                } else if (this.currentUser.roles.includes("ROLE_MODERATOR")) {
                  this.items = [
                    { title: "Home", route: "/home", icon: "mdi-home" },
                    {
                      title: "Profile",
                      route: "/profile",
                      icon: "mdi-account",
                    },
                    {
                      title: "About",
                      route: "/about",
                      icon: "mdi-information",
                    },
                    { title: "Logout", icon: "mdi-logout" },
                  ];
                } else if (this.currentUser.roles.includes("ROLE_USER")) {
                  this.items = [
                    { title: "Home", route: "/home", icon: "mdi-home" },
                    {
                      title: "Profile",
                      route: "/profile",
                      icon: "mdi-account",
                    },
                    {
                      title: "About",
                      route: "/about",
                      icon: "mdi-information",
                    },
                    { title: "Logout", icon: "mdi-logout" },
                  ];
                }
                this.$router.push("/home");
              },
              (error) => {
                this.loading = false;
                this.message =
                  (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                  error.message ||
                  error.toString();
                this.snackBarColor = "red";
                this.snackBarText = "Invalid User!";
                this.loggedIn = false;
                this.snackbar = true;
                this.errored = true;
              }
            );
          } else {
            this.snackBarColor = "red";
            this.snackBarText = "Invalid User!";
            this.snackbar = true;
            this.loggedIn = false;
          }
        })
        .catch(() => {
          this.snackBarColor = "red";
          this.snackBarText = "Invalid User!";
          this.snackbar = true;
          this.errored = true;
          this.loggedIn = false;
        });
    },
    logout() {
      this.$store.dispatch("auth/logout");
      // this.$router.push('/login');
      this.userName = null;
      this.password = null;
      this.loginStatus = !this.loginStatus;
      this.currentUser = null;
    },

    initialize() {
      console.log(this.currentUser);
      if (this.currentUser.roles.includes("ROLE_ADMIN")) {
        this.items = [
          { title: "Home", route: "/home", icon: "mdi-home" },
          { title: "BDA Zones", route: "/bdazones", icon: "mdi-home-city" },
          {
            title: "Access Control",
            route: "/accesscontrol",
            icon: "mdi-lock",
          },
          { title: "Profile", route: "/profile", icon: "mdi-account" },
          { title: "About", route: "/about", icon: "mdi-information" },
          { title: "Logout", icon: "mdi-logout" },
        ];
      } else if (this.currentUser.roles.includes("ROLE_MODERATOR")) {
        this.items = [
          { title: "Home", route: "/home", icon: "mdi-home" },
          { title: "Profile", route: "/profile", icon: "mdi-account" },
          { title: "About", route: "/about", icon: "mdi-information" },
          { title: "Logout", icon: "mdi-logout" },
        ];
      } else if (this.currentUser.roles.includes("ROLE_USER")) {
        this.items = [
          { title: "Home", route: "/home", icon: "mdi-home" },
          { title: "Profile", route: "/profile", icon: "mdi-account" },
          { title: "About", route: "/about", icon: "mdi-information" },
          { title: "Logout", icon: "mdi-logout" },
        ];
      }
    },
  },
};
</script>
